.Circle {
  position: relative;
  fill: lightcoral;
}

.Line {
  fill: lightcoral;
  position: relative;
  display: block;
  top: -160px;
  left: 155px;
}