*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: bisque;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.SvgHolder {
  position: absolute;
}

.TopLevel {
  position: relative;
  height: 100vh;
}

.ContentContainers {
  width: 100%;
  position: absolute;
}

.ViewContainers {
  width: 100%;
  height: 100%;
  position: fixed;
}

.HeadView {
  min-height: 20%;
  position: static;
  z-index: -1;
  display: inline-block;
}

.BodyView {
  min-height: 60%;
  position: static;
  background-color: white;
  display: inline-block;
  z-index: 0;
}

.FootView {
  position: fixed;
  bottom: 0;
  right: 0;
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 10px;
  /* border-radius: 5px; Optional: for rounded corners */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); Optional: for a slight shadow */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 200px; /* Adjust as needed */
  min-height: 50px; /* Adjust as needed */
  font-family: 'Roboto', serif;
  font-size: xx-large;
  font-weight: 300;
  color: grey;
  text-align: right;
}

.FootText {
  margin-right: 10vw;
  font-family: 'Roboto', serif;
  font-size: xx-large;
  font-weight: 300;
  text-align: right;
  color: grey;
}

.StartLower {
  margin-top: 30vh;
}

.EventBlock {
  display: flex;
  align-items: center;
}

.Text {
  color: lightcoral;
  font-size: xx-large;
  font-family: 'Roboto', serif;
  font-weight: 300;
  padding-left: 2vh;
}

@media (max-width: 600px) {
  .Text {
    font-size: x-large; /* Adjust as necessary to get the desired size on mobile */
  }
}

.Space {
  padding-left: 5vw;
  padding-right: 5vw;
}

.Title, .Subtitle, .CallToAction, .CallToActionSymbol {
  color: lightcoral;
  font-family: 'Roboto', serif;
  font-weight: 300;
  box-sizing: border-box;
  text-align: center;
  padding-left: 0; /* Ensure there is no padding pushing content to the side */
  margin: 0 auto; /* Center the content */
}

.Title {
  font-size: xxx-large;
  padding-left: 50px;
  padding-top: 30vh;
}

.Subtitle {
  font-size: xxx-large;
  padding-left: 50px;
  padding-top: 2vh;
}

.CallToAction {
  font-size: xx-large;
  padding-top: 2vh;
  width: 100%;
}

.CallToActionSymbol {
  font-size: xxx-large;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 2vh;
}
